import { makeAutoObservable } from "mobx";

export function createPageLayoutStore() {
	return makeAutoObservable({
		chapters: [],
		chapterSections: { cover: [] },
		specialSections: [],
		zoomActive: false,
		loaded: false,
		addChapter(index, chapter) {
			this.chapters[index] = chapter;
		},
		addSection(chapterKey, index, section) {
			if (!this.chapterSections[chapterKey]) {
				this.chapterSections[chapterKey] = [];
			}
			this.chapterSections[chapterKey][index] = section;
		},
		addSpecialSection(key, section) {
			this.specialSections[key] = section;
		},
		setZoom(zoom) {
			this.zoomActive = zoom;
		},
		setLoaded(loaded) {
			this.loaded = loaded;
		},
		get backgroundColors() {
			let colors = [];
			let previousBackgroundColor;
			for (const chapter of this.chapters) {
				const chapterBg = chapter.theme?.background
					? chapter.theme.background.toLowerCase()
					: null;
				if (!previousBackgroundColor) {
					previousBackgroundColor = chapterBg;
				}
				if (previousBackgroundColor !== chapterBg && chapterBg) {
					let lastColor = colors[colors.length - 1];
					if (lastColor && chapter.start - 200 - lastColor.start <= 1) {
						colors.pop();
					}
					colors.push({
						start: chapter.start - 200,
						end: chapter.start + 200,
						from: previousBackgroundColor,
						to: chapterBg,
						type: "chapter",
					});
					colors.push({
						start: chapter.end - 200,
						end: chapter.end + 200,
						from: chapterBg,
						to: previousBackgroundColor,
						type: "chapter",
					});
				}
				if (chapterBg) {
					previousBackgroundColor = chapterBg;
				}
				let sections = this.chapterSections[chapter.key];
				if (sections) {
					for (const section of sections) {
						const sectionBg = section.theme?.background
							? section.theme.background.toLowerCase()
							: chapterBg;
						if (sectionBg && previousBackgroundColor !== sectionBg) {
							let lastColor = colors[colors.length - 1];
							if (lastColor && section.start - 200 - lastColor.start <= 1) {
								colors.pop();
							}
							colors.push({
								start: section.start - 200,
								end: section.start + 200,
								from: previousBackgroundColor,
								to: sectionBg,
								type: "section",
							});
							if (sectionBg !== chapterBg) {
								colors.push({
									start: section.end - 200,
									end: section.end + 200,
									from: sectionBg,
									to: chapterBg,
									type: "section",
								});
							}
						}
						if (sectionBg) {
							previousBackgroundColor = sectionBg;
						}
						if (previousBackgroundColor)
							if (section.labels.indexOf("timelines") !== -1) {
								let special = this.specialSections;
								for (const specialElement of special) {
									const specialBg = specialElement.theme?.background
										? specialElement.theme.background.toLowerCase()
										: sectionBg;
									if (specialBg && previousBackgroundColor !== specialBg) {
										let lastColor = colors[colors.length - 1];
										if (
											lastColor &&
											specialElement.start - 200 - lastColor.start <= 1
										) {
											colors.pop();
										}
										colors.push({
											start: specialElement.start - 200,
											end: specialElement.start + 200,
											from: previousBackgroundColor,
											to: specialBg,
											type: "special",
										});
										if (specialBg !== sectionBg) {
											colors.push({
												start: specialElement.end - 200,
												end: specialElement.end + 200,
												from: specialBg,
												to: sectionBg,
												type: "special",
											});
										}
									}
									if (specialBg) {
										previousBackgroundColor = specialBg;
									}
								}
							}
					}
				}
			}
			colors.pop();
			return colors;
		},
	});
}
