import React, { createContext, useContext, useRef } from "react";
import { createPageLayoutStore } from "../stores/createPageLayoutStore";

const PageLayoutContext = createContext({});

export function usePageLayout() {
	return useContext(PageLayoutContext);
}

export const PageLayout = (props) => {
	const store = useRef(createPageLayoutStore());
	return <PageLayoutContext.Provider value={store.current} {...props} />;
};
