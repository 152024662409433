function importDirectory(context, exportedKey = false) {
	let cache = {};
	let name = /^\.\/(.+)\.js$/;
	context.keys().forEach((key) => {
		return (cache[name.exec(key)[1]] = exportedKey
			? context(key)[exportedKey]
			: context(key));
	});
	return cache;
}

export default importDirectory;
